import React, { useState, useEffect } from "react";
import "hammerjs";
import { Spin } from "antd";
import Chart from "react-google-charts";
import "./Dashboard.css";
import { GoProjectRoadmap } from "react-icons/go";
import { useNavigate } from "react-router";
import Layout from "../../components/global/layout";
import { API_URL } from "../../environment";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const DashBoard = () => { 
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState({});
  const [employeeProjectData, setEmployeeProjectData] = useState({});
  const [announcementsData, setannouncementsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    userProjects();
    userDetails();
    announcements();
  }, []);

  const userDetails = () => {
    const userid = localStorage.getItem("USERID");
    fetch(`${API_URL}userDetail?id=${userid}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setEmployeeData(res);
      });
  };

  const userProjects = () => {
    fetch(`${API_URL}getprojectstatuscount`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setEmployeeProjectData(res.data || {});
      });
  };
  const today = new Date().toISOString().split("T")[0];
  const employeeDob = employeeData.data?.dob;
 
  const announcements = () => {
    setLoading(true);
    fetch(`${API_URL}announcementListing?page=1&count=1000000`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // Get today's date in YYYY-MM-DD format
       

        // Filter announcements to show only today's announcements
        const todayAnnouncements = res.data.filter(
          (announcement) => announcement.date === today
        );
        setannouncementsData(todayAnnouncements);
        setLoading(false);
      });
  };

  const totalProjects =
    (employeeProjectData.InProgress?.length || 0) +
    (employeeProjectData.Failed?.length || 0) +
    (employeeProjectData.Success?.length || 0);
  const completedProjects = employeeProjectData.Success?.length || 0;
  const inProgressProjects = employeeProjectData.InProgress?.length || 0;
  const failedProjects = employeeProjectData.Failed?.length || 0;

  const chartData = [
    ["Status", "Count"],
    ["Total", totalProjects],
    ["Completed", completedProjects],
    ["In Progress", inProgressProjects],
    ["Failed", failedProjects],
  ];

  const chartsOptions = {
    title: "Project Status Overview",
    hAxis: { title: "", minValue: 0 },
    vAxis: { title: "" },
    legend: "none",
    colors: ["#ff8f27"],
  };

  return (
    <Layout>
     <Spin spinning={loading}>
     <div className="main_dashboards">
        <div className="row py-3">
          <div className="col-sm-12">
            <h3 className="main-title-new">
              Welcome{" "}
              <span className="primary font800">
                {employeeData.data?.fullName}
              </span>
            </h3>
          </div>

          <div className="mt-2">
            <div className="row">
              <div className="col-12 col-md-2 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-start">
                      <div className="dashtitles mb-2">
                        <p>Total Projects: {totalProjects}</p>
                      </div>
                      <div className="dashtitles mb-2">
                        <p>Completed: {completedProjects}</p>
                      </div>
                      <div className="dashtitles mb-2">
                        <p>Working: {inProgressProjects}</p>
                      </div>
                      <div className="dashtitles mb-2">
                        <p>Failure: {failedProjects}</p>
                      </div>
                      <div className="icons_dash mt-3">
                        <GoProjectRoadmap
                          onClick={() => {
                            navigate("/projectassigntable");
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-2 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-start">
                      <div className="dashtitles mb-2">
                        <p>Total Leaves: 00</p>
                      </div>
                      <div className="dashtitles mb-2">
                        <p>Paid Leaves Taken: 00</p>
                      </div>
                      <div className="dashtitles mb-2">
                        <p>Unpaid Leaves Taken: 00</p>
                      </div>
                      <div className="dashtitles mb-2">
                        <p>Remaining Leave: 00</p>
                      </div>
                      <div className="icons_dash mt-3">
                        <GoProjectRoadmap
                          onClick={() => {
                            navigate("/projectassigntable");
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-2 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="dashtitles">
                        <h4 className="">{inProgressProjects}</h4>
                        <p>Working</p>
                      </div>
                      <div className="icons_dash">
                        <GoProjectRoadmap
                          onClick={() => {
                            navigate("/projectassigntable");
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-2 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="dashtitles">
                        <h4 className="">{failedProjects}</h4>
                        <p>Failure</p>
                      </div>
                      <div className="icons_dash">
                        <GoProjectRoadmap
                          onClick={() => {
                            navigate("/projectassigntable");
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-12"></div>
            </div>
          </div>
        </div>

          {/* {employeeData.data?.fullName && (
            <marquee className="marquee">
              <p className="marquee_div">
                Wish You a Very Happy Birthday {employeeData.data.fullName}
              </p>
            </marquee>
          )} */}
          {employeeDob === today ? (
        <marquee className="marquee">
          <p className="marquee_div">
            Wish You a Very Happy Birthday {employeeData.data.fullName}
          </p>
        </marquee>
      ) :""}
        <div className="row py-3">
          <div className="col-sm-8 mb-3">
            <div className="chart_div">
              <Chart
                chartType="AreaChart"
                width="100%"
                height="400px"
                data={chartData}
                options={chartsOptions}
                loader={<Spin />}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="images_data ">
              <div className="postds_ones">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center w-100 justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <div className="users_list">
                          <h4 className="">Announcements </h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="card_announcements">
                      {loading ? (
                        <Spin className="text-center mx-auto" />
                      ) : announcementsData.length > 0 ? (
                        announcementsData.map((announcement, index) => (
                          <div key={index} className="">
                            <img
                              src={API_URL + announcement.image}
                              className="anouments"
                              alt="Announcement"
                            />
                            <div className="mt-2">
                              <p>{announcement.description}</p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="text-center text-xs">No announcements for today.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     </Spin>
     

    </Layout>
  );
};

export default DashBoard;