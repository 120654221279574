import React, { useEffect, useState } from "react";
import { Button, Spin, Tooltip, Image, Modal, Input } from "antd";
import SectionHeader from "../../components/global/SectionHeader";
import { getannouncement,submitReply} from "../../api/api";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";
import { API_URL } from "../../environment";
import { notification } from "antd";

const AnnouncementsDetail = (props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const userid = localStorage.getItem("USERID");
  const [loading, setloading] = useState(false);
  const [desc, setDescription] = useState();
  const [imgs, setImage] = useState();
  const [clientNames, setClientName] = useState();
  const [categoryNames, setcategoryName] = useState();
  const [replyModalVisible, setReplyModalVisible] = useState(false);
  const [replyText, setReplyText] = useState('');
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  useEffect(() => {
    getProject(id);
  }, [id]);
  const getProject = async () => {
    setloading(true);
    try {
      const resp = await getannouncement(id);
      if (resp && resp.status === 200) {
        const list = resp;
        const description = list.data.data.description;
        const clientName = list.data.data.subject;
        setDescription(description);
        setClientName(clientName);
        setcategoryName(list.data.data.category);
        setImage(API_URL + list.data.data.image);

        setloading(false);
      }
    } catch (e) {
      setloading(false);
    }
  };const handleReplySubmit = async () => {
    try {
      const payload = {
              content: replyText,
              announcementId: id,
              addedBy:userid,
            };
    
      const resp = await submitReply(payload );
      if (resp && resp.status === 200) {
        setReplyModalVisible(false);
        notification.success({
          message: "Reply sent",
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handleReply = () => {
    setReplyModalVisible(true);
  };
  
  const navigate = useNavigate();
  const renderSubmitBack = () => {
    navigate("/announcementlist");
  };
  return (
    <Layout>
      <div className="text-left">
        <div className="headings">
          <div className="d-flex align-items-center gap-3">
            <Tooltip title="Back Button">
              <p
                onClick={renderSubmitBack}
                className="pointer text-white  fs-3"
              >
                <BsArrowLeftSquare />
              </p>
            </Tooltip>
            <SectionHeader title="Announcements Details" />
          </div>
        </div>

        <Spin spinning={loading}>
          <div className="">
            <div className="row">
              <div class="col-12 col-md-8">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Subject:</label>
                      </div>
                      <div className="label_text">
                        <span> {clientNames}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="project_deatil">
                      <div className="label_heading">
                        <label className="text-bold">Category:</label>
                      </div>
                      <div className="label_text">
                        <span> {categoryNames}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="project_deatil">
                  <div className="label_heading">
                    <label className="text-bold">Reason:</label>
                  </div>
                  <div className="label_text">
                    <span class="prettify pl-1" style={{ marginTop: "-3px" }}>
                      {" "}
                      {htmlToReactParser.parse(desc)}
                    </span>
                  </div>
                </div>
                <Button type="primary" onClick={handleReply}>
              Reply
            </Button>
              </div>
              <div class="col-12 col-md-4 col docs ">
                <div className="project_deatil">
                  <div className="label_heading">
                    <label className="text-bold">Image:</label>
                  </div>
                  <div className="upload_images mb-5">
                    {imgs == "" || imgs == null ? null : (
                      <span className="imagcl">
                        {" "}
                        {<Image width={200} className="h-auto" src={imgs} />}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
      <Modal
        title="Reply to Announcement"
        visible={replyModalVisible}
        onOk={handleReplySubmit}
        onCancel={() => setReplyModalVisible(false)}
      >
        <Input.TextArea
          value={replyText}
          onChange={(e) => setReplyText(e.target.value)}
          placeholder="Enter your reply"
        />
      </Modal>
    </Layout>
  );
};

export default AnnouncementsDetail;
