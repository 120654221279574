import React, { useEffect, useState } from "react";
import { Button, Tooltip, notification } from "antd";
import { Spin } from "antd/lib";
import { useNavigate, useParams } from "react-router";
import SectionHeader from "../../components/global/SectionHeader"; 
import ApiClient from "../../methods/api/apiClient";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

export default function ProjectTeam() {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [datamember, setdatamember] = useState([]);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const renderSubmitBack = () => {
    navigate(-1);
  };
  // For getting the team details
  const Getdata = () => {
    setloading(true);
    ApiClient.get(`project?id=${id}`)
      .then((res) => {
        setdatamember(res.data.members);
        setdata(res.data);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        notification.error({ message: "Some error occurred" });
      });
  };
  useEffect(() => {
    Getdata();
  }, []);
  return (
    <Layout>
       <div className="main-content">
    <div className="headings">
            <div className="d-flex align-items-center gap-3">
              <Tooltip title="Back Button">
                <p onClick={renderSubmitBack} className="pointer text-white  fs-3">
                  <BsArrowLeftSquare />
                </p>
              </Tooltip>
              <SectionHeader title="Team Details" />
            </div>
          </div>
      <div className="">
        <div className="section">
       
          <Spin spinning={loading}>
            <div className="shadow p-4  ">
              <h5 className="project_detail_text">
                Project Name :-<b className="project_names"> {data.name}</b>
              </h5>

              <div className=" mt-4">
                <h5 className="team_member">Developers :-</h5>
                {datamember.map((item, value) => (
                  <div className="mt-1 d-inline-flex mx-3 ">
                    <h6 >
                      {value + 1}. {item.fullName}({item.designation})
                    </h6>
                  </div>
                ))}
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
    </Layout>
  );
}
