import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Modal } from 'antd';
import logo from "../../utilities/logo.png";
import { BiUser } from "react-icons/bi";
import { TbLayoutDashboard } from "react-icons/tb"; 
import { GrAnnounce } from "react-icons/gr";
import { SlCalender } from "react-icons/sl";
import { LuPartyPopper } from "react-icons/lu";
import { MdOutlineAssignment } from "react-icons/md";
import { IoLinkSharp, IoLogOutOutline } from "react-icons/io5";
import { FiFolderPlus } from "react-icons/fi";
import { VscTarget } from "react-icons/vsc";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { PiAddressBook } from "react-icons/pi";
import { MdOutlinePreview } from "react-icons/md";
import { AiOutlineGift } from "react-icons/ai";
import { API_URL } from "../../environment";
import { notification } from "antd";
import { LiaUserSolid } from "react-icons/lia";
import { GoLock } from "react-icons/go";

const styles = {
  fontWeight: "bold",
  color: "#535356",
};

const SideBar = ({ active, setActive }) => {
  const [isActive, setIsActive] = useState(false);
  const [checkinTime, setCheckinTime] = useState([]);
  const role = localStorage.getItem("role");
  let history = useNavigate();
  let uniqueid = [];
  uniqueid =
    checkinTime &&
    checkinTime.map((fetchid, id) => {
      return fetchid.id;
    });

    const handleLogOut = () => {
      const attId = localStorage.getItem("attId");
      const token = localStorage.getItem("token");
      const PunchIn = localStorage.getItem("PunchIn");
      if (PunchIn == "true") {
        fetch(`${API_URL}attendance/checkout?attendanceId=${attId}`, {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }).then(async (res) => {
          const respose = await res.json();
          if (respose.success == true) {
            
            notification.success({ message: "PunchOut Successfully!" });
            localStorage.clear();
            history("/");
            notification.success({
              message: "Logout Successfully",
            });

          } else {
            notification.error({
              message: "UnAuthorized",
              description: "Login Again! PunchOut Manually",
            });
            localStorage.clear();
            history("/");
            notification.success({
              message: "Logout Successfully",
            });
          }
        });
      } else {
        history("/");
        notification.success({
          message: "Logout Successfully",
        });
        localStorage.clear();
      }
    };

  return (  
    <div>
      <div className="sidebars_lists">
        {active == "home" ? (
          role == "subadmin" ? (
            <ul className=" ullistsli">
              <li className="nav-item">
                <NavLink
                  to="/hrdashboard"
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  //
                >
                  <TbLayoutDashboard />
                  <span>Dashboard</span>
                </NavLink>
              </li>

              <li className="nav-item ">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/attendance"
                >
                  <SlCalender />
                  <span>Attendance</span>
                </NavLink>
              </li>

              <li className="nav-item ">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/knowledgesharinglist"
                >
                  <FaRegShareFromSquare />
                  <span>Knowledge Sharing</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/leavelist"
                >
                  <LuPartyPopper />
                  <span>Leaves</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/resignation"
                >
                  <PiAddressBook />
                  <span>Resignation</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/appraisal"
                >
                  <AiOutlineGift />

                  <span>Appraisal</span>
                </NavLink>
              </li>
            </ul>
          ) : (
            <ul className="ullistsli ">
              <li className="nav-item  ">
                <NavLink
                  to="/dashboard"
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                >
                  <TbLayoutDashboard />

                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  to="/announcementlist"
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                >
                  <GrAnnounce />
                  <span>Announcements</span>
                </NavLink>
              </li>

              <li className="nav-item  ">
                <NavLink
                  to="/holidaylist"
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                >
                  <LuPartyPopper />

                  <span>Holidays</span>
                </NavLink>
              </li>

              <li className="nav-item ">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/attendance"
                >
                  <SlCalender />
                  <span>Attendance</span>
                </NavLink>
              </li>

              <li className="nav-item ">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/projectassigntable"
                >
                  <MdOutlineAssignment />

                  <span>Assigned Projects</span>
                </NavLink>
              </li>

              {role == "bde" ? (
                <div>
                  <li className="nav-item ">
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? "nav-link active_side" : "nav-link"
                      }
                      to="/allProject"
                    >
                      <FiFolderPlus />

                      <span> ALL Project</span>
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? "nav-link active_side" : "nav-link"
                      }
                      to="/monthtarget"
                    >
                      <VscTarget />

                      <span>Target</span>
                    </NavLink>
                  </li>
                </div>
              ) : (
                <></>
              )}
              <li className="nav-item ">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/knowledgesharinglist"
                >
                  <FaRegShareFromSquare />

                  <span>Knowledge Sharing</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/leavelist"
                >
                  <LuPartyPopper />
                  <span>Leaves</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/resignation"
                >
                  <PiAddressBook />

                  <span>Resignation</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/reviewprojectlist"
                >
                  <MdOutlinePreview />

                  <span>Review Project</span>
                </NavLink>
              </li>
            </ul>
          )
        ) : active == "profile" ? (
          <>
            <div className="images_data"></div>

            <ul className="ullistsli ">
              <li className="nav-item ">
                <NavLink
                  onClick={() => {
                    setActive("profile");
                  }}
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/myprofile"
                >
                  <LiaUserSolid />

                  <span>Profile</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  onClick={() => {
                    setActive("profile");
                  }}
                  className={(navData) =>
                    navData.isActive ? "nav-link active_side" : "nav-link"
                  }
                  to="/changepassword"
                >
                  <GoLock />

                  <span>Change Password</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  
                  onClick={() => {
                        Modal.confirm({
                          title: "Confirm Logout",
                          content: "Are you sure you want to logout?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: () => {
                            handleLogOut()
                            
                          },
                        });
                      }}
                  className="nav-link"
                >
                  <IoLogOutOutline />

                  <span>Logout</span>
                </NavLink>
              </li>
            </ul>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SideBar;
