import axios from "axios";
import { API_URL } from "../environment";

const token = localStorage.getItem("token");

export const updateStatus = (id, data) => {
  return axios.put(`${API_URL}project/update/?id=${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAppraisal = () => {
  return axios.get(`${API_URL}getallappraisal?count=100000`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectDetails = (id) => {
  return axios.get(`${API_URL}project?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getsingleleave = (id) => {
  return axios.get(`${API_URL}getsingleleave?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getannouncement = (id) => {
  return axios.get(`${API_URL}announcement?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getholiday = (id) => {
  return axios.get(`${API_URL}holiday?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};



export const getsingleresign = (id) => {
  return axios.get(`${API_URL}getsingleresign?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPostDetails = (id) => {
  return axios.get(`${API_URL}post?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getComments =(knowledge_share_id)=>{
  return axios.get(`${API_URL}comments?knowledge_share_id=${knowledge_share_id}`,{
    headers:{
        "Content-type":"application/json",
        Authorization:`Bearer ${token}`,
    },
  });
};

export const loginApi = (data) => {
  return axios.post(`${API_URL}user/signin`, data, {});
};
 
export const submitReply = (data) => {
  return axios.post(`${API_URL}add-reply`, data, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    }, 
  });
};
 
export const updatexlstatus = (id, data) => {
  return axios.put(`${API_URL}Weeklyplan?id=${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const xlsingledata = (id) => {
  return axios.get(`${API_URL}getsingleWeeklyplan?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const ApiKey = {
  api: process.env.REACT_APP_API_BASE_URL || "http://162.222.203.236:4090/",
  frontendurl: "http://162.222.203.236:4090/",
};
