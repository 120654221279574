import React, { useState, useEffect } from "react";
import "hammerjs";
import { notification, Spin, Tooltip } from "antd";
import { TbLayoutDashboard } from "react-icons/tb"; 
import { Modal } from 'antd';
// import "./Dashboard.css";
import { useNavigate } from "react-router";
import { RiHome6Line } from "react-icons/ri";
import { GrAnnounce } from "react-icons/gr";
import { SlCalender } from "react-icons/sl";
import { LuPartyPopper } from "react-icons/lu";
import { MdOutlineAssignment } from "react-icons/md";
import { IoLinkSharp } from "react-icons/io5";
import { FiFolderPlus } from "react-icons/fi";
import { VscTarget } from "react-icons/vsc";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { PiAddressBook } from "react-icons/pi";
import { MdOutlinePreview } from "react-icons/md";
import { AiOutlineGift } from "react-icons/ai";
import logo from "../../utilities/logo.png";
import Header from "./Header";
import SideBar from "./SideBar";
import { IoLogOutOutline } from "react-icons/io5";
import { API_URL } from "../../environment";

const Layout = ({ children }) => {
  const history = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [active, setActive] = useState("home");
  const role = localStorage.getItem("role");
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };  

  useEffect(() => {
    if (
      window?.location?.pathname == "/myprofile" ||
      window?.location?.pathname == "/changepassword" ||
      window?.location?.pathname == "/editprofile"
    ) {
      setActive("profile");
    } else {
      setActive("home");
    }
  }, []);

  const handleHome = () => {
    setActive("home");
    if (role == "subadmin") {
      history("/hrdashboard");
    } else {
      history("/dashboard");
    }
  };
  const handleAnnouncement = () => {
     setActive("home");
    if (role == "subadmin") {
      history("/hrdashboard");
    } else {
      history("/announcementlist");
    }
  };
  const handleholidaylist = () => {
    setActive("home");
   if (role == "subadmin") {
     history("/hrdashboard");
   } else {
     history("/holidaylist");
   }
 };
 const handleattendance = () => {
  setActive("home");
 if (role == "subadmin") {
   history("/hrdashboard");
 } else {
   history("/attendance");
 }
};
const handleprojectassign = () => {
  setActive("home");
 if (role == "subadmin") {
   history("/hrdashboard");
 } else {
   history("/projectassigntable");
 }
};
const handleknowledgesharing = () => {
  setActive("home");
 if (role == "subadmin") {
   history("/hrdashboard");
 } else {
   history("/knowledgesharinglist");
 }
};
const handleLeave = () => {
  setActive("home");
 if (role == "subadmin") {
   history("/hrdashboard");
 } else {
   history("/leavelist");
 }
};
const handleresignation = () => {
  setActive("home");
 if (role == "subadmin") {
   history("/hrdashboard");
 } else {
   history("/resignation");
 }
};
const handlereviewproject = () => {
  setActive("home");
 if (role == "subadmin") {
   history("/hrdashboard");
 } else {
   history("/reviewprojectlist");
 }
};
const handleallproject = () => {
  setActive("home");
 if (role == "subadmin") {
   history("/hrdashboard");
 } else {
   history("/allProject");
 }
};
const handletarget = () => {
  setActive("home");
 if (role == "subadmin") {
   history("/hrdashboard");
 } else {
   history("/monthtarget");
 }
};

  const handleLogout  = () => {
    const attId = localStorage.getItem("attId");
    const token = localStorage.getItem("token");
    const PunchIn = localStorage.getItem("PunchIn");
    if (PunchIn == "true") {
      fetch(`${API_URL}attendance/checkout?attendanceId=${attId}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(async (res) => {
        const respose = await res.json();
        if (respose.success == true) {
          
          notification.success({ message: "PunchOut Successfully!" });
          localStorage.clear();
          history("/");
          notification.success({
            message: "Logout Successfully",
          });

        } else {
          notification.error({
            message: "UnAuthorized",
            description: "Login Again! PunchOut Manually",
          });
          localStorage.clear();
          history("/");
          notification.success({
            message: "Logout Successfully",
          });
        }
      });
    } else {
      history("/");
      notification.success({
        message: "Logout Successfully",
      });
      localStorage.clear();
    }
  };
  return (
    <>
      <div className="mainwrapper_index">
        <div className="all_flex">
          <div className="w80 h-100 ">
            <div className="flex_cols">
            <div className="logos_lists">
              <p className="mb-4">
                <img className="logo_fav" src={logo} />
              </p>
              <ul className="ulclass autoscroll ">
                
                <Tooltip placement="right" title="Dashboard">
                  <li
                    onClick={() => {
                      handleHome();
                    }}
                    // className={`${active == "home" ? "active_li" : ""}`}
                  >
                    <TbLayoutDashboard />
                  </li>
                </Tooltip>
                <Tooltip placement="right" title="Announcement">
                  <li
                    onClick={() => {
                      handleAnnouncement();
                    }}
                    // className={`${active == "home" ? "active_li" : ""}`}
                  >
                    <GrAnnounce />
                  </li>
                </Tooltip>
                <Tooltip placement="right" title="Holidays">
                  <li
                    onClick={() => {
                      handleholidaylist();
                    }}
                    // className={`${active == "home" ? "active_li" : ""}`}
                  >
                    <LuPartyPopper />
                  </li>
                </Tooltip>
                <Tooltip placement="right" title="Attendance">
                  <li
                    onClick={() => {
                      handleattendance();
                    }}
                    // className={`${active == "home" ? "active_li" : ""}`}
                  >
                    <SlCalender />
                  </li>
                </Tooltip>
                <Tooltip placement="right" title="Assigned Projects">
                  <li
                    onClick={() => {
                      handleprojectassign();
                    }}
                    // className={`${active == "home" ? "active_li" : ""}`}
                  >
                    <MdOutlineAssignment />
                  </li>
                </Tooltip>
                {role == "bde" ? (
                  <Tooltip placement="right" title="All Projects">
                  <li
                    onClick={() => {
                      handleallproject();
                    }}
                    // className={`${active == "home" ? "active_li" : ""}`}
                  >
                    <FiFolderPlus />
                  </li>
                </Tooltip>
                

                ): (<></>)}
                {role == "bde" ? (
                  <Tooltip placement="right" title="Target">
                  <li
                    onClick={() => {
                      handletarget();
                    }}
                    // className={`${active == "home" ? "active_li" : ""}`}
                  >
                    <VscTarget />
                  </li>
                </Tooltip>
                

                ): (<></>)}

                <Tooltip placement="right" title="Knowledge Sharing">
                  <li
                    onClick={() => {
                      handleknowledgesharing();
                    }}
                    // className={`${active == "home" ? "active_li" : ""}`}
                  >
                    <FaRegShareFromSquare />
                  </li>
                </Tooltip>
                <Tooltip placement="right" title="Leaves">
                  <li
                    onClick={() => {
                      handleLeave();
                    }}
                    // className={`${active == "home" ? "active_li" : ""}`}
                  >
                    <LuPartyPopper />
                  </li>
                </Tooltip>
                <Tooltip placement="right" title="Resignation">
                  <li
                    onClick={() => {
                      handleresignation();
                    }}
                    // className={`${active == "home" ? "active_li" : ""}`}
                  >
                    <PiAddressBook />
                  </li>
                </Tooltip>
                <Tooltip placement="right" title="Review Project">
                  <li
                    onClick={() => {
                      handlereviewproject();
                    }}
                    // className={`${active == "home" ? "active_li" : ""}`}
                  >
                    <MdOutlinePreview />
                  </li>
                </Tooltip>
              </ul>
              </div>

              <div className="seetings_list text-center">
                <ul className="ulclass">
                <Tooltip placement="right" title="Logout">
                    <li
                      onClick={() => {
                        Modal.confirm({
                          title: "Confirm Logout",
                          content: "Are you sure you want to logout?  ",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: () => {
                            handleLogout()
                          },
                        });
                      }}
                      className={`${active == "profile" ? "" : ""}`}
                    >
                     <IoLogOutOutline className="text-xl" />
                    </li>
                  </Tooltip>
                  {/* <Tooltip placement="right" title="Profile">
                    <li
                      onClick={() => {
                        setActive("profile");
                        history("/myprofile");
                      }}
                      className={`${active == "profile" ? "active_li" : ""}`}
                    >
                      <img
                        className="seting_logo"
                        src="assets/img/avatar/avatar-4.png"
                      />
                    </li>
                  </Tooltip> */}
                </ul>
              </div>
              
            </div>
          </div>
          <div
            className={`wraptwos ${
              isOpen ? "open inner_wraps_close" : " inner_wraps"
            }`}
          >
            <div className="d-flex align-items-center bordrs_btn p-2">
              <p className="subheads p-2">
                {active == "profile" ? "Settings" : "Home"}
              </p>
            </div>

            <div className="allilists w-100">
              <SideBar active={active} setActive={setActive} />
            </div>
          </div>

          <div className="main_contents ">
            <div className="header_top">
              <div className="d-flex align-items-center justify-content-between w-100 bordrs_btn px-3">
                <div
                  className={`hamburger ${isOpen ? "open" : ""}`}
                  onClick={toggleMenu}
                >
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
                <div className="">
                  <Header />
                </div>
              </div>
            </div>

            <div className="childins_data">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Layout;
