import React, { useEffect, useState } from "react";
import { XLSX } from "xlsx";
import {
  Form,
  Button,
  notification,
  Select,
  Divider,
  Spin,
  Tooltip,
} from "antd";
import { Modal, TextArea } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import SectionHeader from "../../components/global/SectionHeader";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BrowserRouter, Route, useParams } from "react-router-dom";
import ApiClient from "../../api/apiClient";
import {
  addProject,
  getEmployee,
  getDev,
  getBde,
  getQa,
  getAdmin,
} from "../../api/user/userapi";
import { Navigate, useNavigate } from "react-router";
import Input from "rc-input";
import { API_URL } from "../../environment";
import axios from "axios";
import { setAuthorizationToken } from "../../api/auth";
import Layout from "../../components/global/layout";
import { BsArrowLeftSquare } from "react-icons/bs";

const ProjectChart = (props) => {
  const EXTENSIONS = ["xlsx", "xls", "csv"];
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [projectValue, setProjectValue] = useState({
    name: "",
    frontend_employees: [],
    backend_employees: [],
    documents: [],
  });

  const [teamValue, setTeamValue] = useState({});
  const [arrayTeam, setArrayTeam] = useState([]);
  const [arrayBDE, setArrayBDE] = useState([]);
  const [arrayQA, setArrayQa] = useState([]);
  const [arrayAdmin, setArrayAdmin] = useState([]);
  // const [loading, setloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [UPdescription, setUPDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const handleFileChange = async (e, multiple = true) => {
    // setLoading(true);
    let files = e.target.files;
    let i = 0;
    let imgfile = [];
    for (let item of files) {
      imgfile.push(item);
    }
    let images = [];
    if (uploadedFiles) images = uploadedFiles;
    setAuthorizationToken(axios);
    for await (let item of imgfile) {
      let file = files.item(i);
      let formData = new FormData();
      formData.append("file", file);
      const res = await axios.post(
        `${API_URL}project/uploadDocument?modelName=jobs`,
        formData
      );
      let image = null;
      try {
        if (res?.data?.success) {
          if (Array.isArray(res?.data?.data)) {
            image = res?.data?.data[0].fullpath;
          } else {
            image = res?.data?.data?.fullpath;
          }
          if (!multiple) {
            images = [image];
            setUploadedFiles(images);
            setProjectValue((prevProjectValue) => ({
              ...prevProjectValue,
              documents: [image],
            }));
          } else {
            images.push(image);
            setUploadedFiles(images);
            setProjectValue((prevProjectValue) => ({
              ...prevProjectValue,
              documents: [...prevProjectValue.documents, image],
            }));
          }
        }
      } catch (error) {
        console.log(error);
      }
      i++;
    }
    if (multiple) {
      setUploadedFiles(images);
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    if (id) {
      setIsModalOpen(true);
    } else {
      handleSubmit(e);
    }
  };
  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const getdata = () => {
    setLoading(true);
    ApiClient.get(`project?id=${id}`).then((res) => {
      if (res.success) {
        setLoading(false);

        setProjectValue({
          ...res.data,
          frontend_employees: res?.data?.frontend_employees?.map(
            (item) => item?.id
          ),
          backend_employees: res?.data?.backend_employees?.map(
            (item) => item?.id
          ),
        });
        setTeamValue(res.data);
      }
      setLoading(false);
    });
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      ...projectValue,
      update_description: UPdescription,
      addedBy: id,
    };

    if (payload?.BDE?.id) {
      payload["BDE"] = payload["BDE"].id;
    }
    if (payload?.QA?.id) {
      payload["QA"] = payload["QA"].id;
    }
    if (payload["project_manager"]?.id) {
      payload["project_manager"] = payload["project_manager"].id;
    }

    if (!id) {
      try {
        const userResp = await addProject(payload);
        navigate("/allProject");
      } catch (e) {
        notification.error({
          message: "Project Already Exist",
        });
      } finally {
        setLoading(false);
      }
    } else {
      ApiClient.put(`project/update?id=${id}`, payload).then((res) => {
        if (res.success) {
          setIsModalOpen(false);
          navigate("/allProject");
        }
      });
    }
  };
  const renderSubmitBack = () => {
    navigate("/allProject");
  };
  useEffect(() => {
    if (id) {
      getdata();
    }
  }, []);

  useEffect(() => {
    getTeam();
    userDetails();
    userBDEDetails();
    userQADetails();
    userAdminDetails();
  }, []);

  const getTeam = async () => {
    try {
      const resp = await getEmployee();
      if (resp && resp.status === 200) {
        const list = resp;
        const listing = list.data.data;
      }
    } catch (e) {}
  };

  const userDetails = async () => {
    try {
      setLoading(true);

      const resp = await getDev();
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        const newarraydata = arrayData.filter((item) => {
          return item.role != "HR" && item.role != "subadmin";
        });
        setArrayTeam(newarraydata);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      localStorage.clear();
      history("/");
      setTimeout(() => {
        notification.error({
          message: "Unauthorized",
          description: "Your Session Has Expired. Please logIn.",
        });
      }, 1000);
    }
  };

  const userBDEDetails = async () => {
    try {
      setLoading(true);

      const resp = await getBde();
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        const newarraydata = arrayData.filter((item) => {
          return item.role != "HR" && item.role != "subadmin";
        });
        setArrayBDE(newarraydata);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      localStorage.clear();
      history("/");
      setTimeout(() => {
        notification.error({
          message: "Unauthorized",
          description: "Your Session Has Expired. Please logIn.",
        });
      }, 1000);
    }
  };
  const userQADetails = async () => {
    try {
      setLoading(true);

      const resp = await getQa();
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        const newarraydata = arrayData.filter((item) => {
          return item.role != "HR" && item.role != "subadmin";
        });
        setArrayQa(newarraydata);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      localStorage.clear();
      history("/");
      setTimeout(() => {
        notification.error({
          message: "Unauthorized",
          description: "Your Session Has Expired. Please logIn.",
        });
      }, 1000);
    }
  };

  const userAdminDetails = async () => {
    try {
      setLoading(true);

      const resp = await getAdmin();
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        const newarraydata = arrayData.filter((item) => {
          return item.role != "HR" && item.role != "subadmin";
        });
        setArrayAdmin(newarraydata);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      localStorage.clear();
      history("/");
      setTimeout(() => {
        notification.error({
          message: "Unauthorized",
          description: "Your Session Has Expired. Please logIn.",
        });
      }, 1000);
    }
  };
  const [form] = Form.useForm();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const [colDefs, setColDefs] = useState();
  const [data, setData] = useState();

  const getExention = (file) => {
    const parts = file.name.split(".");
    const extension = parts[parts.length - 1];
    return EXTENSIONS.includes(extension); // return boolean
  };

  const convertToJson = (headers, data) => {
    const rows = [];
    data.forEach((row) => {
      let rowData = {};
      row.forEach((element, index) => {
        rowData[headers[index]] = element;
      });
      rows.push(rowData);
    });
    return rows;
  };

  const importExcel = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (event) => {
      //parse data
      const bstr = event.target.result;
      const workBook = XLSX.read(bstr, { type: "binary" });
      //get first sheet
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      //convert to array
      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      const headers = fileData[0];
      const heads = headers.map((head) => ({ title: head, field: head }));
      setColDefs(heads);
      //removing header
      fileData.splice(0, 1);
      setData(convertToJson(headers, fileData));
    };

    if (file) {
      if (getExention(file)) {
        reader.readAsBinaryString(file);
      } else {
        notification.error({
          message: "Invalid file format.",
        });
        return;
      }
    } else {
      setData([]);
      setColDefs([]);
    }
  };

  return (
    <Layout>
      <Spin spinning={loading}>
        <div>
          <div id="fulldiv"></div>
          <div className="main-content">
            <div className="headings">
              <div className="d-flex align-items-center gap-3">
                <Tooltip title="Back Button">
                  <p
                    onClick={renderSubmitBack}
                    className="pointer text-white  fs-3"
                  >
                    <BsArrowLeftSquare />
                  </p>
                </Tooltip>
                <SectionHeader
                  title={!id ? "Create Project" : "Edit Project"}
                />
              </div>
            </div>

            <div className="section">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="detail-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#detail"
                    type="button"
                    role="tab"
                    aria-controls="detail"
                    aria-selected="true"
                  >
                    Details
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="frontend-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#frontend"
                    type="button"
                    role="tab"
                    aria-controls="frontend"
                    aria-selected="false"
                  >
                    Frontend
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="admin-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#admin"
                    type="button"
                    role="tab"
                    aria-controls="admin"
                    aria-selected="false"
                  >
                    Admin
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="api-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#api"
                    type="button"
                    role="tab"
                    aria-controls="api"
                    aria-selected="false"
                  >
                    API
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="team-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#team"
                    type="button"
                    role="tab"
                    aria-controls="team"
                    aria-selected="false"
                  >
                    Team
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="detail"
                  role="tabpanel"
                  aria-labelledby="detail-tab"
                >
                  <div className="mt-4">
                    <form onSubmit={handleSubmit}>
                      <div className="row m-0">
                        <div className="col-md-6">
                          <label>
                            Project Name<span className="star">*</span>
                          </label>
                          <input
                            className="form-control pl-2"
                            value={projectValue.name ? projectValue.name : ""}
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                name: e.target.value,
                              })
                            }
                            placeholder="Enter project name"
                            type="text"
                            name="name"
                            required
                          ></input>
                        </div>
                        <div className="col-md-6">
                          <label>
                            Client Name<span className="star">*</span>
                          </label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.clientName
                                ? projectValue.clientName
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                clientName: e.target.value,
                              })
                            }
                            placeholder="Enter client name"
                            type="text"
                            name="clientname"
                            required
                          ></input>
                        </div>
                        <div className="col-md-12 mt-4">
                          <label>
                            Description<span className="star">*</span>
                          </label>
                          <textarea
                            className="form-control w-100 ml-0 p-2"
                            value={
                              projectValue.description
                                ? projectValue.description
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                description: e.target.value,
                              })
                            }
                            placeholder="Enter project description"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            required
                          ></textarea>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>
                            Bucket<span className="star">*</span>
                          </label>
                          <br />
                          <select
                            className="w-100 mb-2 border selectbox"
                            value={projectValue.git ? projectValue.git : ""}
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                git: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select Bucket</option>
                            <option value="github">Github</option>
                            <option value="bitbucket">Bitbucket</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-4 ">
                          <label>Total Hours</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.totaltime
                                ? projectValue.totaltime
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                totaltime: e.target.value,
                              })
                            }
                            placeholder="Enter total-hours"
                            type="number"
                            id="appt"
                            name="appt"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>
                            Start Date<span className="star">*</span>
                          </label>
                          <input
                            className="form-control pl-2"
                            name="startDate"
                            value={
                              projectValue.startDate
                                ? projectValue.startDate
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                startDate: e.target.value,
                              })
                            }
                            type="date"
                            required
                          />
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>
                            End Date<span className="star">*</span>
                          </label>
                          <input
                            type="date"
                            name="endDate"
                            className="form-control pl-2"
                            selectsEnd
                            value={
                              projectValue.endDate ? projectValue.endDate : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                endDate: e.target.value,
                              })
                            }
                            min={projectValue.startDate}
                            required
                          />
                        </div>

                        <div className="col-md-6 mt-4">
                          <label>
                            Frontend Technology<span className="star">*</span>
                          </label>
                          <br />
                          <select
                            className="w-100 border mb-4 selectbox"
                            value={
                              projectValue.frontendtechnology
                                ? projectValue.frontendtechnology
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                frontendtechnology: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select Frontend Technology</option>
                            <option value="React.js">React JS</option>
                            <option value="Next.js">Next JS</option>
                            <option value="Nest.js">Nest JS</option>
                            <option value="Angular.js">Angular</option>
                            <option value="HTML">HTML</option>
                            <option value="React-Native">React Native</option>
                            <option value="Vue.js">Vue JS</option>
                          </select>
                        </div>

                        <div className="col-md-6 mt-4">
                          <label>
                            Backend Technology<span className="star">*</span>
                          </label>
                          <br />
                          <select
                            className="w-100 border mb-4 selectbox"
                            value={
                              projectValue.backendtechnology
                                ? projectValue.backendtechnology
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                backendtechnology: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select Backend Technology</option>
                            <option value="Python">Python</option>
                            <option value="Node JS">Node JS</option>
                            <option value="PHP">PHP</option>
                            <option value="Java">Java</option>
                            <option value="C#">C#</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Production URL</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.productionWebsiteUrl
                                ? projectValue.productionWebsiteUrl
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                productionWebsiteUrl: e.target.value,
                              })
                            }
                            placeholder="Enter  Production URL "
                            type="text"
                            name="productionWebsiteUrl"
                          ></input>
                        </div>

                        <div className="col-md-6 mt-4">
                          <label>Upload Documents</label>
                          <br />
                          <Input
                            type="file"
                            multiple
                            onChange={(e) => handleFileChange(e)}
                          />
                          {/* <ul>
                          {uploadedFiles.map((file, index) => (
                            <li key={index}>{file?.image}</li>
                          ))}
                        </ul> */}
                        </div>
                      </div>
                      <div className="col-md-12 my-3 text-right">
                        <button
                          className="btn btn-primary p-2"
                          onClick={(e) => {
                            e.preventDefault();
                            const nextTab =
                              document.getElementById("frontend-tab");
                            nextTab.click();
                          }}
                        >
                          {"NEXT"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="frontend"
                  role="tabpanel"
                  aria-labelledby="frontend-tab"
                >
                  <div className="mt-4">
                    <form onSubmit={handleSubmit}>
                      <div className="row m-0"></div>

                      {/* <Divider orientation="left">Frontend</Divider> */}
                      <div className="row m-0">
                        <div className="col-md-6 mt-4">
                          <label>Frontend Repository Name</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.frontend_repository_name
                                ? projectValue.frontend_repository_name
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                frontend_repository_name: e.target.value,
                              })
                            }
                            placeholder="Enter frontend repository name"
                            type="text"
                            name="repo"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Frontend Owner Workspace Id</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.frontend_owner_workspace_id
                                ? projectValue.frontend_owner_workspace_id
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                frontend_owner_workspace_id: e.target.value,
                              })
                            }
                            placeholder="Enter frontend owner workspace id"
                            type="text"
                            name="workspaceid"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Frontend Clone Link</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.frontend_clone_link
                                ? projectValue.frontend_clone_link
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                frontend_clone_link: e.target.value,
                              })
                            }
                            placeholder="Enter frontend clone link"
                            type="text"
                            name="frontendcloneLink"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Frontend App Password</label>
                          <input
                            type="text"
                            className="form-control pl-2"
                            value={
                              projectValue.frontend_clone_password
                                ? projectValue.frontend_clone_password
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                frontend_clone_password: e.target.value,
                              })
                            }
                            placeholder="Enter frontend app password"
                          />
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>
                            Is Frontend Updated Branch Merge With Master Or Not
                          </label>
                          <br />
                          <select
                            className="w-100 border mb-2 selectbox"
                            value={
                              projectValue.is_frontend_updated_branch_merged_with_master
                                ? projectValue.is_frontend_updated_branch_merged_with_master
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                is_frontend_updated_branch_merged_with_master:
                                  e.target.value,
                              })
                            }
                          >
                            <option value="">Please Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Frontend Updated Branch</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.updated_branch_frontend
                                ? projectValue.updated_branch_frontend
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                updated_branch_frontend: e.target.value,
                              })
                            }
                            placeholder="Enter Frontend updated Branch name"
                            type="text"
                            name="branchName"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Frontend Staging URL</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.frontendStagingWebsiteUrl
                                ? projectValue.frontendStagingWebsiteUrl
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                frontendStagingWebsiteUrl: e.target.value,
                              })
                            }
                            placeholder="Enter Staging URL"
                            type="text"
                            name="frontendStagingWebsiteUrl"
                          ></input>
                        </div>
                      </div>

                      <div className="col-md-12 my-3 text-right">
                        <button
                          className="btn btn-primary p-2"
                          onClick={(e) => {
                            e.preventDefault();
                            const nextTab =
                              document.getElementById("admin-tab");
                            nextTab.click();
                          }}
                        >
                          {"NEXT"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="admin"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <div className="mt-4">
                    <form onSubmit={handleSubmit}>
                      <div className="row m-0"></div>

                      <div className="row m-0">
                        <div className="col-md-6 mt-4">
                          <label>Admin Repository Name</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.admin_repository_name
                                ? projectValue.admin_repository_name
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                admin_repository_name: e.target.value,
                              })
                            }
                            placeholder="Enter admin repository name"
                            type="text"
                            name="repo"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Admin Owner Workspace Id</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.admin_owner_workspace_id
                                ? projectValue.admin_owner_workspace_id
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                admin_owner_workspace_id: e.target.value,
                              })
                            }
                            placeholder="Enter admin owner workspace id"
                            type="text"
                            name="workspaceid"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Admin Clone Link</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.admin_clone_link
                                ? projectValue.admin_clone_link
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                admin_clone_link: e.target.value,
                              })
                            }
                            placeholder="Enter admin clone link"
                            type="text"
                            name="admincloneLink"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Admin App Password</label>
                          <input
                            type="text"
                            className="form-control pl-2"
                            value={
                              projectValue.admin_clone_password
                                ? projectValue.admin_clone_password
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                admin_clone_password: e.target.value,
                              })
                            }
                            placeholder="Enter admin app password"
                          />
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>
                            Is Admin Updated Branch Merge With Master Or Not
                          </label>
                          <br />
                          <select
                            className="w-100 border mb-2 selectbox"
                            value={
                              projectValue.is_admin_updated_branch_merged_with_master
                                ? projectValue.is_admin_updated_branch_merged_with_master
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                is_admin_updated_branch_merged_with_master:
                                  e.target.value,
                              })
                            }
                          >
                            <option value="">Please Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Admin Updated Branch</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.updated_branch_admin
                                ? projectValue.updated_branch_admin
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                updated_branch_admin: e.target.value,
                              })
                            }
                            placeholder="Enter Admin updated branch name"
                            type="text"
                            name="branchName"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Admin Staging URL</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.backendStagingWebsiteUrl
                                ? projectValue.backendStagingWebsiteUrl
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                backendStagingWebsiteUrl: e.target.value,
                              })
                            }
                            placeholder="Enter Staging URL"
                            type="text"
                            name="backendStagingWebsiteUrl"
                          ></input>
                        </div>

                        <div className="col-md-12 my-3 text-right">
                          <button
                            className="btn btn-primary p-2"
                            onClick={(e) => {
                              e.preventDefault();
                              const nextTab =
                                document.getElementById("api-tab");
                              nextTab.click();
                            }}
                          >
                            {"NEXT"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  class="tab-pane fade "
                  id="api"
                  role="tabpanel"
                  aria-labelledby="api-tab"
                >
                  <div className="mt-4">
                    <form onSubmit={handleSubmit}>
                      <div className="row m-0"></div>

                      <div className="row m-0">
                        <div className="col-md-6 mt-4">
                          <label>API Repository Name</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.api_repository_name
                                ? projectValue.api_repository_name
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                api_repository_name: e.target.value,
                              })
                            }
                            placeholder="Enter api repository name"
                            type="text"
                            name="repo"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>API Owner Workspace Id</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.api_owner_workspace_id
                                ? projectValue.api_owner_workspace_id
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                api_owner_workspace_id: e.target.value,
                              })
                            }
                            placeholder="Enter api owner workspace id"
                            type="text"
                            name="workspaceid"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>API Clone Link</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.backend_clone_link
                                ? projectValue.backend_clone_link
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                backend_clone_link: e.target.value,
                              })
                            }
                            placeholder="Enter api clone link"
                            type="text"
                            name="apicloneLink"
                          ></input>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>API App Password</label>
                          <input
                            type="text"
                            value={
                              projectValue.backend_clone_password
                                ? projectValue.backend_clone_password
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                backend_clone_password: e.target.value,
                              })
                            }
                            className="form-control pl-2"
                            placeholder="Enter api app password"
                          />
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>Is API Branch Merge With Master Or Not</label>
                          <br />
                          <select
                            className="w-100 border mb-2 selectbox"
                            value={
                              projectValue.is_api_updated_branch_merged_with_master
                                ? projectValue.is_api_updated_branch_merged_with_master
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                is_api_updated_branch_merged_with_master:
                                  e.target.value,
                              })
                            }
                          >
                            <option value="">Please Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>API Updated Branch</label>
                          <input
                            className="form-control pl-2"
                            value={
                              projectValue.updated_branch_api
                                ? projectValue.updated_branch_api
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                updated_branch_api: e.target.value,
                              })
                            }
                            placeholder="Enter API updated branch name"
                            type="text"
                            name="branchName"
                          ></input>
                        </div>

                        <div className="col-md-12 my-3 text-right">
                          <button
                            className="btn btn-primary p-2"
                            onClick={(e) => {
                              e.preventDefault();
                              const nextTab =
                                document.getElementById("team-tab");
                              nextTab.click();
                            }}
                          >
                            {"NEXT"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="team"
                  role="tabpanel"
                  aria-labelledby="team-tab"
                >
                  <div className="mt-4">
                    <form onSubmit={handleSubmit}>
                      <div className="row m-0">
                        <div className="col-md-6 mt-4">
                          <label>Project Manager</label>
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select Manager"
                            optionFilterProp="children"
                            onChange={(value) =>
                              setProjectValue({
                                ...projectValue,
                                project_manager: value,
                              })
                            }
                            value={projectValue?.project_manager?.fullName}
                          >
                            {arrayAdmin &&
                              arrayAdmin.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.fullName} ({item.email})
                                </Option>
                              ))}
                          </Select>
                        </div>

                        <div className="col-md-6 mt-4">
                          <label>BDE Members</label>
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select Team Members"
                            optionFilterProp="children"
                            value={projectValue?.BDE?.fullName}
                            onChange={(value) =>
                              setProjectValue({
                                ...projectValue,
                                BDE: value,
                              })
                            }
                          >
                            {arrayBDE &&
                              arrayBDE.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.fullName} ({item.designation})
                                </Option>
                              ))}
                          </Select>
                        </div>
                        <div className="col-md-6 mt-4">
                          <label>QA Members</label>
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select Team Members"
                            optionFilterProp="children"
                            value={projectValue?.QA?.fullName}
                            onChange={(value) =>
                              setProjectValue({
                                ...projectValue,
                                QA: value,
                              })
                            }
                          >
                            {arrayQA &&
                              arrayQA.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.fullName} ({item.designation})
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </div>
                      <div className="row m-0">
                        <div className="col-md-6 mt-4">
                          <label>Frontend Team Members</label>
                          <Select
                            mode="multiple"
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select Team Members"
                            optionFilterProp="children"
                            onChange={(value) =>
                              setProjectValue({
                                ...projectValue,
                                frontend_employees: value,
                              })
                            }
                            value={projectValue?.frontend_employees}
                          >
                            {arrayTeam &&
                              arrayTeam.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.fullName} ({item.designation})
                                </Option>
                              ))}
                          </Select>
                        </div>

                        <div className="col-md-6 mt-4">
                          <label>Backend Team Members</label>
                          <Select
                            mode="multiple"
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select Team Members"
                            optionFilterProp="children" 
                            value={projectValue?.backend_employees}
                            onChange={(value) =>
                              setProjectValue({
                                ...projectValue,
                                backend_employees: value,
                              })
                            }
                          >
                            {arrayTeam &&
                              arrayTeam.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.fullName} ({item.designation})
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-12 my-3 text-right">
                        <button
                          type="submit"
                          className="btn btn-primary p-2"
                          onClick={(e) => {
                            handleUpdate(e);
                          }}
                        >
                          {!id ? "SUBMIT" : "UPDATE"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            title="Update Project Description"
            visible={isModalOpen}
            onOk={handleSubmit}
            onCancel={handleModalCancel}
          >
            <Form>
              <Form.Item label="Description">
                <textarea
                  value={UPdescription}
                  className="form-control"
                  onChange={(e) => setUPDescription(e.target.value)}
                  placeholder="Enter project description"
                ></textarea>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Spin>
    </Layout>
  );
};

export default ProjectChart;
